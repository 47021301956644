module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.5_@swc+core@1.5.7_@swc+helpers@0.5.2__babe_cwmcqt4y5x2kodzf2zp5spdpx4/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-8N63W5TEJN","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.5_@swc+core@1.5.7_@swc+helpers@0.5.2__babel-eslint@10.1.0_eslint@8.55.0__esbuild@_ww7mitzlwhndzcwo2h757q4k7y/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
